// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-clients-and-projects-js": () => import("../src/pages/clients-and-projects.js" /* webpackChunkName: "component---src-pages-clients-and-projects-js" */),
  "component---src-pages-contact-us-js": () => import("../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-how-we-work-js": () => import("../src/pages/how-we-work.js" /* webpackChunkName: "component---src-pages-how-we-work-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-test-page-js": () => import("../src/pages/test-page.js" /* webpackChunkName: "component---src-pages-test-page-js" */)
}

