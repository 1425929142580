import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
    palette: {
    primary: {
      light: '#335b97',
      main: '#01337D',
      dark: '#002357',
      contrastText: '#E6F6FF',
    },
    secondary: {
      light: '#377f8b',
      main: '#05606E',
      dark: '#03434d',
      contrastText: '#E1FCF8',
    },
    custom1: {
      main: '#7CC4FA',  
    }
    
  },
});

export default theme;